import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import figureFormatter           from "@/services/utils/figureFormatter";
import { useStore } from "vuex";
import useDate from '@/services/utils/day';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

const pdfPrinter = () => {
    const { formatDate } = useDate();
    const store = useStore();
    const { commaFormat } = figureFormatter();

    const userName = store.state.user.user.name;

    const exportToPDF = (company, data, payload, printType='Payable') => {

        var doc = {
            pageSize: 'A4',
            pageMargins: [ 30, 100, 30, 80 ],
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(data, payload, printType),


            styles : {
                header: {
                    fontSize: 24
                }
            },

            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: `${printType} Register`
            }
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (data, payload, printType) => {
        return [
            {
                text: `${printType} Register`,
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                decoration: 'underline'
            },

            {
                stack: [
                    {
                        text: `Date: ${payload.startDate} - ${payload.endDate}`
                    },
                    {
                        text: `Role: ${payload.role ? payload.role : ''}`
                    },
                    {
                        text: `Party: ${payload.party ? payload.party : ''}`
                    }
                ]
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: [ 'auto', 'auto', 'auto','auto','auto', 'auto', 'auto', 'auto'],
                    body: data
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },
        ]
    }

    const formatMoneyReceiptData = (data, partyTitle='SUPPLIER') => {

        let formatData = [
            getTableHead(partyTitle)
        ];

        formatData = formatData.concat(getTableRow(data));
        formatData.push(getTotals(data));

        return formatData;
    }

    const getTableRow = (data) => {
       return data.map((item, index) => {
            return [
                { text: index+1 },
                { text: item.serial_no },
                { text: item.name },
                { text: item.phone },
                { text: commaFormat(item.opening_balance), alignment: 'right' },
                { text: commaFormat(item.debit), alignment: 'right' },
                { text: commaFormat(item.credit), alignment: 'right' },
                { text: commaFormat(item.closing_balance), alignment: 'right' }
            ];
        })
    }

    const getTableHead = (partyTitle) => {
        return [
            { text: 'SL NO' },
            { text: 'ID' },
            { text: `${partyTitle} NAME` },
            { text: 'MOBILE' },
            { text: 'OPENING BALANCE' , alignment: 'right' },
            { text: 'DEBIT' , alignment: 'right' },
            { text: 'CREDIT' , alignment: 'right' },
            { text: 'CLOSING BALANCE' , alignment: 'right' },
        ]
    }

    const getTotals = (data) => {

        return [
            { text: 'Total', colSpan: 3, alignment: 'right' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: commaFormat(getTotal(data, 'opening_balance')), alignment: 'right' },
            { text: commaFormat(getTotal(data, 'debit')), alignment: 'right' },
            { text: commaFormat(getTotal(data, 'credit')), alignment: 'right' },
            { text: commaFormat(getTotal(data, 'closing_balance')), alignment: 'right' }
        ];
    }

    const getTotal = (data, key) => {
      return data.reduce((sum, item) => sum + Number(item[key]), 0)
    }

    const getHeader = (company) => {
        return {
            margin: [ 30, 25, 30, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60
                },

                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email
                    ]
                }
            ]
        }
    }

    const getFooter = (userName) =>{
        return {
            margin: [ 25, 0 ],
            columns: [
                {
                    columns: [
                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]
                },

                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    return {
        exportToPDF,
        formatMoneyReceiptData
    }
}

export default pdfPrinter;
